const env = {
  external: {
    // change
    API: 'external', // 'cloud' or 'local or 'frmcloud'
    API_NEW: true, // set to false for old Cosmos DB version
    API_ENDPOINT: 'https://api.kpmgregulatoryhorizon.com', // 'https://4i7jtr1948.execute-api.eu-west-1.amazonaws.com/Prod'
    API_SQL_ENDPOINT: 'https://api.kpmgregulatoryhorizon.com', // 'https://4i7jtr1948.execute-api.eu-west-1.amazonaws.com/Prod'
    AWS_APP_WEB_DOMAIN: 'frmcloud.auth.us-east-1.amazoncognito.com',
    AWS_CLIENT_ID_CLOUD: '7cp7g2u6i49i5ba1qeko8olj6u',
    AWS_CLIENT_ID_LOCAL: '52qppbmsgo695fk7fgdan02pe7',
    AWS_IDENTITY_POOL_ID: 'us-east-1:40f5f197-ca55-4e01-8518-af7190cc61d8',
    AWS_REDIRECT_URI_SIGN_IN_NODE: '/auth/callback',
    AWS_REDIRECT_URI_SIGN_OUT_NODE: '/',
    AWS_REGION: 'us-east-1',
    AWS_USER_POOL_ID: 'us-east-1_GFdxy2qfQ',
    AWS_LOGINS: 'cognito-idp.us-east-1.amazonaws.com/us-east-1_GFdxy2qfQ',
    AWS_TOKENSCOPESARRAY: ['profile', 'openid'],
    AWS_DYNAMODB_TABLE_ANNOTATIONS: 'annotations-dev1',
    AWS_DYNAMODB_TABLE_REGULATIONS: 'regulations-dev1',
    BUILD: 'external',
    DOMAIN: 'https://kpmgregulatoryhorizon.com',
    DOMAIN_REGSTORE: 'https://d3j40fadoyw6ni.cloudfront.net',
    DOMAIN_LOCAL: 'http://localhost:8080',
    DOMAIN_LOCAL_REGSTORE: 'https://d3j40fadoyw6ni.cloudfront.net',
    GOOGLE_ANALYTICS: 'G-7P39DJ6YRY',
    // change
    ENV: 'cloud', // change for release - cloud or local
    REG_TEXT_PATH_NODE: '/dhsi7eusd0x/',
    SHOW_LOGOUT: true
  },
  externalUAT: {
    // change
    API: 'external', // 'cloud' or 'local or 'frmcloud'
    API_NEW: true, // set to false for old Cosmos DB version
    API_ENDPOINT: 'https://av4k2hud4d.execute-api.eu-west-1.amazonaws.com/uat',
    API_SQL_ENDPOINT: 'https://av4k2hud4d.execute-api.eu-west-1.amazonaws.com/uat',
    AWS_APP_WEB_DOMAIN: 'frmcloud.auth.us-east-1.amazoncognito.com',
    AWS_CLIENT_ID_CLOUD: '7cp7g2u6i49i5ba1qeko8olj6u',
    AWS_CLIENT_ID_LOCAL: '52qppbmsgo695fk7fgdan02pe7',
    AWS_IDENTITY_POOL_ID: 'us-east-1:40f5f197-ca55-4e01-8518-af7190cc61d8',
    AWS_REDIRECT_URI_SIGN_IN_NODE: '/auth/callback',
    AWS_REDIRECT_URI_SIGN_OUT_NODE: '/',
    AWS_REGION: 'us-east-1',
    AWS_USER_POOL_ID: 'us-east-1_GFdxy2qfQ',
    AWS_LOGINS: 'cognito-idp.us-east-1.amazonaws.com/us-east-1_GFdxy2qfQ',
    AWS_TOKENSCOPESARRAY: ['profile', 'openid'],
    AWS_DYNAMODB_TABLE_ANNOTATIONS: 'annotations-dev1',
    AWS_DYNAMODB_TABLE_REGULATIONS: 'regulations-dev1',
    BUILD: 'external',
    DOMAIN: 'https://sharp-borg-1c3100.netlify.app',
    DOMAIN_REGSTORE: 'https://d3j40fadoyw6ni.cloudfront.net',
    DOMAIN_LOCAL: 'http://localhost:8080',
    DOMAIN_LOCAL_REGSTORE: 'https://d3j40fadoyw6ni.cloudfront.net',
    GOOGLE_ANALYTICS: 'G-7P39DJ6YRY',
    // change
    ENV: 'cloud', // change for release - cloud or local
    REG_TEXT_PATH_NODE: '/dhsi7eusd0x/',
    SHOW_LOGOUT: true
  },
  local: {
    // change
    API: 'local', // 'cloud' or 'local or 'frmcloud'
    API_NEW: true, // set to false for old Cosmos DB version
    API_ENDPOINT: 'https://q8ymoqtjk4.execute-api.eu-west-1.amazonaws.com/prod', // https://l5lbzoe40m.execute-api.eu-west-1.amazonaws.com/prod https://hvmshfzevi.execute-api.us-east-1.amazonaws.com/prod
    API_SQL_ENDPOINT: 'https://q8ymoqtjk4.execute-api.eu-west-1.amazonaws.com/prod',
    AWS_APP_WEB_DOMAIN: 'frmcloud.auth.us-east-1.amazoncognito.com',
    AWS_CLIENT_ID_CLOUD: '7cp7g2u6i49i5ba1qeko8olj6u',
    AWS_CLIENT_ID_LOCAL: '52qppbmsgo695fk7fgdan02pe7',
    AWS_IDENTITY_POOL_ID: 'us-east-1:40f5f197-ca55-4e01-8518-af7190cc61d8',
    AWS_REDIRECT_URI_SIGN_IN_NODE: '/auth/callback',
    AWS_REDIRECT_URI_SIGN_OUT_NODE: '/',
    AWS_REGION: 'us-east-1',
    AWS_USER_POOL_ID: 'us-east-1_GFdxy2qfQ',
    AWS_LOGINS: 'cognito-idp.us-east-1.amazonaws.com/us-east-1_GFdxy2qfQ',
    AWS_TOKENSCOPESARRAY: ['profile', 'openid'],
    AWS_DYNAMODB_TABLE_ANNOTATIONS: 'annotations-dev1',
    AWS_DYNAMODB_TABLE_REGULATIONS: 'regulations-dev1',
    BUILD: 'full',
    DOMAIN: 'https://frmcloud.com',
    DOMAIN_REGSTORE: 'https://d3j40fadoyw6ni.cloudfront.net',
    DOMAIN_LOCAL: 'http://localhost:8080',
    DOMAIN_LOCAL_REGSTORE: 'https://d3j40fadoyw6ni.cloudfront.net',
    GOOGLE_ANALYTICS: 'UA-0-0',
    // change
    ENV: 'local', // change for release - cloud or local
    REG_TEXT_PATH_NODE: '/dhsi7eusd0x/',
    SHOW_LOGOUT: true
  },
  localexternal: {
    // change
    API: 'localexternal', // 'cloud' or 'local or 'frmcloud'
    API_NEW: true, // set to false for old Cosmos DB version
    API_ENDPOINT: 'https://q8ymoqtjk4.execute-api.eu-west-1.amazonaws.com/prod',
    API_SQL_ENDPOINT: 'https://q8ymoqtjk4.execute-api.eu-west-1.amazonaws.com/prod',
    AWS_APP_WEB_DOMAIN: 'frmcloud.auth.us-east-1.amazoncognito.com',
    AWS_CLIENT_ID_CLOUD: '7cp7g2u6i49i5ba1qeko8olj6u',
    AWS_CLIENT_ID_LOCAL: '52qppbmsgo695fk7fgdan02pe7',
    AWS_IDENTITY_POOL_ID: 'us-east-1:40f5f197-ca55-4e01-8518-af7190cc61d8',
    AWS_REDIRECT_URI_SIGN_IN_NODE: '/auth/callback',
    AWS_REDIRECT_URI_SIGN_OUT_NODE: '/',
    AWS_REGION: 'us-east-1',
    AWS_USER_POOL_ID: 'us-east-1_GFdxy2qfQ',
    AWS_LOGINS: 'cognito-idp.us-east-1.amazonaws.com/us-east-1_GFdxy2qfQ',
    AWS_TOKENSCOPESARRAY: ['profile', 'openid'],
    AWS_DYNAMODB_TABLE_ANNOTATIONS: 'annotations-dev1',
    AWS_DYNAMODB_TABLE_REGULATIONS: 'regulations-dev1',
    BUILD: 'external',
    DOMAIN: 'https://frmcloud.com',
    DOMAIN_REGSTORE: 'https://d3j40fadoyw6ni.cloudfront.net',
    DOMAIN_LOCAL: 'http://localhost:8080',
    DOMAIN_LOCAL_REGSTORE: 'https://d3j40fadoyw6ni.cloudfront.net',
    GOOGLE_ANALYTICS: 'UA-0-0',
    // change
    ENV: 'local', // change for release - cloud or local
    REG_TEXT_PATH_NODE: '/dhsi7eusd0x/',
    SHOW_LOGOUT: true
  },
  prod: {
    API: 'cloud', // 'cloud' or 'local
    API_NEW: true,
    API_ENDPOINT: 'https://oo2r8zgbrb.execute-api.eu-west-1.amazonaws.com/prod',
    API_SQL_ENDPOINT: 'https://oo2r8zgbrb.execute-api.eu-west-1.amazonaws.com/prod',
    AWS_APP_WEB_DOMAIN: 'kpmgrc-vol.auth.eu-west-1.amazoncognito.com',
    AWS_CLIENT_ID_CLOUD: '7n6jgjc1fvo7k775fa6ds6rc9t',
    AWS_CLIENT_ID_LOCAL: 'xx',
    AWS_IDENTITY_POOL_ID: 'eu-west-1:00c8d097-2c73-4ae2-a592-adde7f646e12',
    AWS_REDIRECT_URI_SIGN_IN_NODE: '/auth/callback',
    AWS_REDIRECT_URI_SIGN_OUT_NODE: '/auth/callback',
    AWS_REGION: 'eu-west-1',
    AWS_USER_POOL_ID: 'eu-west-1_BAext7Z5B',
    AWS_LOGINS: 'cognito-idp.eu-west-1.amazonaws.com/eu-west-1_BAext7Z5B',
    AWS_TOKENSCOPESARRAY: ['email', 'openid'],
    BUILD: 'full',
    DOMAIN: 'https://kpmgrc.com',
    DOMAIN_LOCAL: 'http://localhost:8080',
    GOOGLE_ANALYTICS: 'UA-111906044-2',
    ENV: 'cloud', // change for release - cloud or local
    SHOW_LOGOUT: true
  },
  frmcloud: {
    // change
    API: 'frmcloud', // 'cloud' or 'local or 'frmcloud'
    API_NEW: true, // set to false for old Cosmos DB version
    API_ENDPOINT: 'https://q8ymoqtjk4.execute-api.eu-west-1.amazonaws.com/prod', // https://l5lbzoe40m.execute-api.eu-west-1.amazonaws.com/prod https://hvmshfzevi.execute-api.us-east-1.amazonaws.com/prod
    // SQL endpoint for comms - same as the main
    API_SQL_ENDPOINT: 'https://q8ymoqtjk4.execute-api.eu-west-1.amazonaws.com/prod',
    AWS_APP_WEB_DOMAIN: 'frmcloud.auth.us-east-1.amazoncognito.com',
    AWS_CLIENT_ID_CLOUD: '7cp7g2u6i49i5ba1qeko8olj6u',
    AWS_CLIENT_ID_LOCAL: '52qppbmsgo695fk7fgdan02pe7',
    AWS_IDENTITY_POOL_ID: 'us-east-1:40f5f197-ca55-4e01-8518-af7190cc61d8',
    AWS_REDIRECT_URI_SIGN_IN_NODE: '/auth/callback',
    AWS_REDIRECT_URI_SIGN_OUT_NODE: '/',
    AWS_REGION: 'us-east-1',
    AWS_USER_POOL_ID: 'us-east-1_GFdxy2qfQ',
    AWS_LOGINS: 'cognito-idp.us-east-1.amazonaws.com/us-east-1_GFdxy2qfQ',
    AWS_TOKENSCOPESARRAY: ['profile', 'openid'],
    AWS_DYNAMODB_TABLE_ANNOTATIONS: 'annotations-dev1',
    AWS_DYNAMODB_TABLE_REGULATIONS: 'regulations-dev1',
    BUILD: 'full',
    DOMAIN: 'https://frmcloud.com',
    DOMAIN_REGSTORE: 'https://d3j40fadoyw6ni.cloudfront.net',
    DOMAIN_LOCAL: 'http://localhost:8080',
    DOMAIN_LOCAL_REGSTORE: 'https://d3j40fadoyw6ni.cloudfront.net',
    // change
    ENV: 'cloud', // change for release - cloud or local
    GOOGLE_ANALYTICS: 'UA-111906044-1',
    REG_TEXT_PATH_NODE: '/dhsi7eusd0x/',
    SHOW_LOGOUT: true
  }
}

let Config = env.prod

if (process.env.VUE_APP_ENV === 'local') {
  Config = env.local
}

if (process.env.VUE_APP_ENV === 'frmcloud') {
  Config = env.frmcloud
}

if (process.env.VUE_APP_ENV === 'localexternal') {
  Config = env.localexternal
}

if (process.env.VUE_APP_ENV === 'external') {
  Config = env.external
}

if (process.env.VUE_APP_ENV === 'externalUAT') {
  Config = env.externalUAT
}

Config.PROCESS_ENV = process.env

if (Config.ENV === 'local') {
  Config.REG_TEXT_PATH = Config.DOMAIN_LOCAL_REGSTORE + Config.REG_TEXT_PATH_NODE
  Config.AWS_CLIENT_ID = Config.AWS_CLIENT_ID_LOCAL
  Config.AWS_REDIRECT_URI_SIGN_IN = Config.DOMAIN_LOCAL + Config.AWS_REDIRECT_URI_SIGN_IN_NODE
  Config.AWS_REDIRECT_URI_SIGN_OUT = Config.DOMAIN_LOCAL + Config.AWS_REDIRECT_URI_SIGN_OUT_NODE
  Config.BASE_URL = Config.DOMAIN_LOCAL
}

if (Config.ENV === 'cloud') {
  Config.REG_TEXT_PATH = Config.DOMAIN_REGSTORE + Config.REG_TEXT_PATH_NODE
  Config.AWS_CLIENT_ID = Config.AWS_CLIENT_ID_CLOUD
  Config.AWS_REDIRECT_URI_SIGN_IN = Config.DOMAIN + Config.AWS_REDIRECT_URI_SIGN_IN_NODE
  Config.AWS_REDIRECT_URI_SIGN_OUT = Config.DOMAIN + Config.AWS_REDIRECT_URI_SIGN_OUT_NODE
  Config.BASE_URL = Config.DOMAIN
}

export default Config
