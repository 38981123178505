<template>
  <div id="app" class="bg-light">
    <div v-if="loading">
      <b-container class="text-center">
        <h1 class="mb-4">loading</h1>
        <h1><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></h1>
      </b-container>
    </div>
    <div v-if="!loading">
      <nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top justify-content-between navbar-top">
        <router-link :to="{ name: 'Start' }" class="navbar-brand mr-4">
          <img :src="baseUrl + '/lg.jpg'" width="107.5" height="43" />
        </router-link>
        <!-- <div class="collapse navbar-collapse justify-content-between bg-white" id="navbarNavDropdown"> -->
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse class="bg-white" id="nav-collapse" is-nav>
          <b-navbar-nav>
            <li class="nav-item" v-if="$config.BUILD === 'full' || key">
                <router-link :to="{ name: 'Start' }" class="nav-link text-primary">Home</router-link>
            </li>
            <b-nav-item-dropdown
              v-if="$config.BUILD === 'full' || key"
              id="regulatory-horizon-dropdown"
              text="Regulatory horizon"
              toggle-class="text-primary"
            >
              <b-dropdown-item><router-link :to="{ name: 'News' }" class="dropdown-item">News</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.readInsights"><router-link :to="{ name: 'Articles' }" class="dropdown-item">Insights</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'Events' }" class="dropdown-item">Events</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'Topics' }" class="dropdown-item">Reg topics</router-link></b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown
              id="obligations-dropdown"
              text="Obligations"
              toggle-class="text-primary"
              v-if="$config.BUILD === 'full'"
            >
              <b-dropdown-item v-if="permissions.regulationRead"><router-link :to="{ name: 'RegDecompEntities' }" class="dropdown-item text-primary">Entities</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.regulationRead"><router-link :to="{ name: 'RegDecompFunctions' }" class="dropdown-item text-primary">Functions</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.regulationRead"><router-link :to="{ name: 'RegDecompJournal' }" class="dropdown-item text-primary">Journal</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.regulationRead"><router-link :to="{ name: 'RegDecompObligations' }" class="dropdown-item text-primary">Obligations</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.regulationRead"><router-link :to="{ name: 'RegDecompRegulations' }" class="dropdown-item text-primary">Regulations</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.relationships"><router-link :to="{ name: 'Relationships' }" class="dropdown-item text-primary">Relationships</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.regulationRead"><router-link :to="{ name: 'RegDecompRequirements' }" class="dropdown-item text-primary">Requirements</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.regulationRead"><router-link :to="{ name: 'Riskcomponents' }" class="dropdown-item text-primary">Risk framework</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.regulationAdd"><router-link :to="{ name: 'RegDecompStatic' }" class="dropdown-item text-primary">Static</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'RegDecompAbout' }" class="dropdown-item text-primary">About</router-link></b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown
              id="impact-dropdown"
              text="IMPACT"
              toggle-class="text-primary"
              v-if="$config.BUILD === 'full'"
            >
              <b-dropdown-item v-if="permissions.assurance"><router-link :to="{ name: 'Assurance' }" class="dropdown-item text-primary">Assurance</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'ImpactExperts' }" class="dropdown-item text-primary">Experts</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'ImpactInitiatives' }" class="dropdown-item text-primary">Initiatives</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.reportingframework"><router-link :to="{ name: 'Reportingframework' }" class="dropdown-item text-primary">Reporting framework</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.reportingfrequency"><router-link :to="{ name: 'Reportingfrequency' }" class="dropdown-item text-primary">Reporting frequency</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.impactstatus"><router-link :to="{ name: 'Impactstatus' }" class="dropdown-item text-primary">Status</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'ImpactTopics' }" class="dropdown-item text-primary">Topics</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'ImpactAbout' }" class="dropdown-item text-primary">About</router-link></b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown
              id="control-dropdown"
              text="Controls"
              toggle-class="text-primary"
              v-if="$config.BUILD === 'full'"
            >
              <b-dropdown-item v-if="permissions.controls"><router-link :to="{ name: 'Controls' }" class="dropdown-item text-primary">Controls</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.projects"><router-link :to="{ name: 'Projects' }" class="dropdown-item text-primary">Projects</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.controls"><router-link :to="{ name: 'ControlRisks' }" class="dropdown-item text-primary">Risks</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.controls"><router-link :to="{ name: 'ControlStatuses' }" class="dropdown-item text-primary">Status</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.controls"><router-link :to="{ name: 'ControlThemes' }" class="dropdown-item text-primary">Themes</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.controls"><router-link :to="{ name: 'Riskcomponents' }" class="dropdown-item text-primary">Risk framework</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'ControlAbout' }" class="dropdown-item text-primary">About</router-link></b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown
              id="faq-dropdown"
              text="FAQs"
              toggle-class="text-primary"
              v-if="$config.BUILD === 'full'"
            >
              <b-dropdown-item v-if="permissions.faqs"><router-link :to="{ name: 'Faqs' }" class="dropdown-item text-primary">FAQs</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.projects"><router-link :to="{ name: 'Projects' }" class="dropdown-item text-primary">Projects</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.ugroup"><router-link :to="{ name: 'UGroups' }" class="dropdown-item text-primary">User groups</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'FaqAbout' }" class="dropdown-item text-primary">About</router-link></b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown
              id="control-dropdown"
              text="MAR"
              toggle-class="text-primary"
              v-if="$config.BUILD === 'full' && permissions.mar"
            >
              <b-dropdown-item v-if="$config.BUILD === 'full'"><router-link :to="{ name: 'Alerts' }" class="dropdown-item text-primary">Alerts</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.assetclass"><router-link :to="{ name: 'Assetclasses' }" class="dropdown-item text-primary">Asset classes</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.case"><router-link :to="{ name: 'Cases' }" class="dropdown-item text-primary">Cases</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.coverage"><router-link :to="{ name: 'Coverage' }" class="dropdown-item text-primary">Coverage</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'MarDashboard' }" class="dropdown-item text-primary">Dashboard</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'Risks' }" class="dropdown-item text-primary">MAR risks</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'Topic', params: { id: 1 }}" class="dropdown-item text-primary">News</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.person"><router-link :to="{ name: 'Persons' }" class="dropdown-item text-primary">Persons</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.persontype"><router-link :to="{ name: 'Persontypes' }" class="dropdown-item text-primary">Person types</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.producttype"><router-link :to="{ name: 'Producttypes' }" class="dropdown-item text-primary">Product types</router-link></b-dropdown-item>
            </b-nav-item-dropdown>
            <li class="nav-item" v-if="$config.BUILD === 'full'">
                <router-link :to="{ name: 'Notifications' }" class="nav-link text-primary">Notifications</router-link>
            </li>
            <b-nav-item-dropdown
              id="admin-dropdown"
              text="Admin"
              toggle-class="text-primary"
              v-if="permissions.adminMenu && $config.BUILD === 'full'"
            >
              <b-dropdown-item v-if="permissions.source"><router-link :to="{ name: 'Sources' }" class="dropdown-item text-primary">Sources</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.sourceAdd"><router-link :to="{ name: 'SourceAdd' }" class="dropdown-item text-primary">Add Source</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.user"><router-link :to="{ name: 'Users' }" class="dropdown-item text-primary">Users</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.userGroup"><router-link :to="{ name: 'UserGroups' }" class="dropdown-item text-primary">User groups</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.migration"><router-link :to="{ name: 'Migrations' }" class="dropdown-item text-primary">Migrations</router-link></b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown
              id="apps-dropdown"
              text="Apps"
              toggle-class="text-primary"
              v-if="$config.BUILD === 'full'"
            >
              <b-dropdown-item v-if="permissions.algotrading"><router-link :to="{ name: 'AppUT', params: { appid: 'algo-trading' }}" class="dropdown-item text-primary">Algorithmic Trading</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.appanalytics"><router-link :to="{ name: 'AppAnalytics' }" class="dropdown-item text-primary">Edge Analytics</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.appobligations"><router-link :to="{ name: 'AppObligations' }" class="dropdown-item text-primary">Obligations</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.nfr"><router-link :to="{ name: 'Ptt' }" class="dropdown-item text-primary">PTT</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.appreporting"><router-link :to="{ name: 'AppReporting' }" class="dropdown-item text-primary">Reporting</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.ut"><router-link :to="{ name: 'AppUT', params: { appid: 'ut' }}" class="dropdown-item text-primary">Unauthorised Trading</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'AppUT', params: { appid: 'about' }}" class="dropdown-item text-primary">About</router-link></b-dropdown-item>
            </b-nav-item-dropdown>
            <li class="nav-item" v-if="$config.BUILD === 'external'">
                <router-link :to="{ name: 'Settings' }" class="nav-link text-primary">Settings</router-link>
            </li>
            <li class="nav-item" v-if="$config.BUILD === 'external'">
                <router-link :to="{ name: 'About' }" class="nav-link text-primary">About</router-link>
            </li>
          </b-navbar-nav>
          <b-navbar-nav v-if="$config.BUILD === 'external' && username" class="ml-auto">
            <b-nav-item right @click="signOutExternal">
              {{ username }} <i class="fa fa-sign-out" aria-hidden="true"></i>
            </b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav v-if="$config.BUILD === 'full'" class="ml-auto">
            <b-nav-item right>
              <span v-if="$config.SHOW_LOGOUT">
                <small><router-link :to="{ name: 'SignOutCognitoAuth' }" class="nav-link text-primary">{{ user.username }} <i class="fa fa-sign-out" aria-hidden="true"></i></router-link></small>
              </span>
              <span v-else>
                <small>{{ user.username }}</small>
              </span>
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </nav>

      <router-view></router-view>
      <div class="mt-4 p-4" style="border-top-color: rgb(0, 145, 218); border-top-style: solid; border-top-width: 9px">
        <h4 class="my-4"><b-link :to="{ name: 'Contact' }">Contact</b-link> | <b-link :to="{ name: 'Legal' }">Legal</b-link></h4>
        <div class="my-2">© 2022 KPMG LLP, a UK limited liability partnership and a member firm of the KPMG global organisation of independent member firms affiliated with KPMG International Limited, a private English company limited by guarantee. All rights reserved.</div>
        <div>For more detail about the structure of the KPMG global organisation please visit <a href="https://home.kpmg/governance">https://home.kpmg/governance</a></div>
      </div>
    </div>

    <b-modal v-model="pingModal" size="xl" ok-only title="Connection error">
      <div>
        <p>Looks like there are some server connection issues</p>
        <p>Please try to login again and if the problem persists contact Lucas Ocelewicz</p>
        <p><router-link :to="{ name: 'SignOutCognitoAuth' }" class="nav-link text-primary">LOGIN</router-link></p>
      </div>
    </b-modal>

  </div>
</template>

<script>
import ac from './libs/accesscontrol'

function getUser () {
  const user = {}
  user.username = window.localStorage.getItem('username')
  user.sub = window.localStorage.getItem('userSub')
  user.state = window.localStorage.getItem('userState')
  user.group = window.localStorage.getItem('userGroup')
  return user
}

export default {
  name: 'app',
  created: async function () {
    this.$logger.debug('App.vue created start')
    this.$logger.verbose('localStorage', window.localStorage)
    this.user = getUser()
    this.newApi = this.$config.API_NEW
    if (this.$store.state.sessionShort === 0) {
      this.$store.commit('setSessionShort', Date.now())
    }
    this.$stat.setSessionShort(this.$store.state.sessionShort)
    let sessionLong = window.localStorage.getItem('frmCloudSession')
    if (sessionLong === null) {
      sessionLong = Date.now()
      window.localStorage.setItem('frmCloudSession', sessionLong)
    } else {
      sessionLong = Number(sessionLong)
    }
    this.$stat.setSessionLong(sessionLong)
    await this.checkUserStatus()
  },
  updated: async function () {
    this.$logger.debug('App.vue started: updated')
    this.$logger.verbose('localStorage', window.localStorage)
    await this.checkUserStatus()
  },
  data () {
    return {
      baseUrl: this.$config.BASE_URL,
      ie: false,
      loading: true,
      newApi: false,
      permissions: {},
      pingModal: false
    }
  },
  computed: {
    key: {
      get () {
        return this.$store.state.key
      },
      set (payload) {
        this.$store.commit('setKey', payload)
      }
    },
    user: {
      get () {
        return this.$store.state.user
      },
      set (payload) {
        this.$store.commit('setUser', payload)
      }
    },
    username: {
      get () {
        return this.$store.state.username
      },
      set (payload) {
        this.$store.commit('setUsername', payload)
      }
    },
    userState: {
      get () {
        return this.$store.state.userState
      },
      set (payload) {
        this.$store.commit('setUserState', payload)
      }
    }
  },
  methods: {
    checkUserStatus: async function () {
      if (this.$config.BUILD === 'full') {
        let user = this.user
        this.$logger.debug('App.vue, started: checkUserStatus')
        this.$logger.verbose('state.user object', this.user)
        this.userState = window.localStorage.getItem('userState')
        let whitelist = ['Auth', 'LoginCognitoAuth', 'SignOutCognitoAuth']
        if (this.userState !== 'signedIn') {
          if (!whitelist.includes(this.$route.name)) {
            window.localStorage.setItem('frmCloudRedirect', window.location.href)
            this.$router.push({ name: 'Auth', params: { action: 'signin' } })
          }
        } else {
          // ping server to check connectivity
          try {
            await this.$Amplify.API.get('cosmos', '/ping')
            window.localStorage.setItem('frmCloudReloginTrigger', 'false')
          } catch (e) {
            if (window.localStorage.getItem('frmCloudReloginTrigger') === 'true') {
              this.pingModal = true
              console.log('ping error, stuck')
            } else {
              window.localStorage.setItem('frmCloudReloginTrigger', 'true')
              window.localStorage.setItem('frmCloudRedirect', window.location.href)
              this.$router.push({ name: 'SignOutCognitoAuth' })
              console.log('ping error, reloging')
            }
          }
          // fetch user from cosmos and check permissions
          try {
            this.$logger.debug('fetching user from cosmos')
            const apiName = 'cosmos'
            const path = `/user/byemail/${user.username}`
            const response = await this.$Amplify.API.get(apiName, path)
            this.$logger.debug('response', response)
            const account = response
            if (account.userGroups.length > 0) {
              user.acgroups = account.userGroups
            } else {
              user.acgroups = ['norole']
            }
          } catch (e) {
            this.$logger.warn('fetching user from cosmos error' + e)
            user.acgroups = ['norole']
          }
          this.user = user
          this.permissions.adminMenu = ac.can(this.user.acgroups).readAny('adminMenu').granted
          this.permissions.algotrading = ac.can(this.user.acgroups).readAny('appalgotrading').granted
          this.permissions.appanalytics = ac.can(this.user.acgroups).readAny('appanalyticsdemo').granted
          this.permissions.appobligations = ac.can(this.user.acgroups).readAny('appobligations').granted
          this.permissions.appreporting = ac.can(this.user.acgroups).readAny('appreporting').granted
          this.permissions.assetclass = ac.can(this.user.acgroups).readAny('assetclass').granted
          this.permissions.assurance = ac.can(this.user.acgroups).readAny('assurance').granted
          this.permissions.case = ac.can(this.user.acgroups).readAny('case').granted
          this.permissions.comm = ac.can(this.user.acgroups).readAny('comm').granted
          this.permissions.controls = ac.can(this.user.acgroups).readAny('control').granted
          this.permissions.coverage = ac.can(this.user.acgroups).readAny('coverage').granted
          this.permissions.faqs = ac.can(this.user.acgroups).readAny('faq').granted
          this.permissions.impactstatus = ac.can(this.user.acgroups).readAny('impactstatus').granted
          this.permissions.mar = ac.can(this.user.acgroups).readAny('marrisk').granted
          this.permissions.migration = ac.can(this.user.acgroups).createAny('migration').granted
          this.permissions.nfr = ac.can(this.user.acgroups).readAny('nfrsection').granted
          this.permissions.person = ac.can(this.user.acgroups).readAny('person').granted
          this.permissions.persontype = ac.can(this.user.acgroups).readAny('persontype').granted
          this.permissions.producttype = ac.can(this.user.acgroups).readAny('producttype').granted
          this.permissions.projects = ac.can(this.user.acgroups).readAny('project').granted
          this.permissions.readInsights = true
          this.permissions.regulationAdd = ac.can(this.user.acgroups).createAny('regulation').granted
          this.permissions.regulationRead = ac.can(this.user.acgroups).readAny('regulation').granted
          this.permissions.relationships = ac.can(this.user.acgroups).readAny('relationship').granted
          this.permissions.reportingframework = ac.can(this.user.acgroups).readAny('reportingframework').granted
          this.permissions.reportingfrequency = ac.can(this.user.acgroups).readAny('reportingfrequency').granted
          this.permissions.source = ac.can(this.user.acgroups).readAny('source').granted
          this.permissions.sourceAdd = ac.can(this.user.acgroups).createAny('source').granted
          this.permissions.ugroup = ac.can(this.user.acgroups).readAny('ugroup').granted
          this.permissions.user = ac.can(this.user.acgroups).readAny('user').granted
          this.permissions.userGroup = ac.can(this.user.acgroups).readAny('userGroup').granted
          this.permissions.ut = ac.can(this.user.acgroups).readAny('apput').granted
        }
        this.loading = false
        this.username = this.user.username
      }
      if (this.$config.BUILD === 'external') {
        let key = window.localStorage.getItem('frmCloudExApiKey')
        if (key === 'null') {
          key = null
          window.localStorage.removeItem('frmCloudExApiKey')
          window.localStorage.removeItem('frmCloudExUsername')
          this.$store.commit('setKey', null)
          this.$store.commit('setUsername', null)
        }
        const username = window.localStorage.getItem('frmCloudExUsername')
        if (!key && this.$route.name !== 'Legal' && this.$route.name !== 'About') {
          this.$router.push({ name: 'Settings' })
        }
        if (key) {
          this.key = key
          this.username = username
          this.user.username = username
          this.$stat.setUserType(1)
          const accountDB = await this.$Amplify.API.get('cosmos', '/account')
          if (!accountDB.acgroups) {
            accountDB.acgroups = ['norole']
          }
          window.localStorage.setItem('frmCloudExAcgroups', JSON.stringify(accountDB.acgroups))
          window.localStorage.setItem('frmCloudExUsername', accountDB.organisation)
          const userDB = this.user
          userDB.acgroups = accountDB.acgroups
          this.user = userDB
          this.permissions.readInsights = ac.can(this.user.acgroups).readAny('article').granted
          this.$store.commit('setUsername', accountDB.organisation)
        }
        this.userState = 'signedIn'
        this.user.acgroups = JSON.parse(window.localStorage.getItem('frmCloudExAcgroups'))
        this.loading = false
      }
      this.$stat.setUser(this.username)
    },
    signOut: function () {
      this.$router.push({ name: 'SignOutCognitoAuth' })
    },
    signOutExternal: function () {
      window.localStorage.removeItem('frmCloudExApiKey')
      window.localStorage.removeItem('frmCloudExUsername')
      this.account = false
      this.$store.commit('setKey', null)
      this.$store.commit('setUsername', null)
    }
  },
  mounted () {
    window.addEventListener('scroll', checkWindowBottom.bind(this))
    async function checkWindowBottom () {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= 0.8 * document.documentElement.scrollHeight
      if (bottomOfWindow) {
        this.$logger.verbose('hit bottom of window')
        this.$store.commit('setWindowBottom', true)
      }
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: KPMGLight;
  // src: url(https://frmcloud.com/resources/fonts/light.woff);
  src: url(./assets/fonts/light.woff);
}

$blue: #005eb8; //primary
$gray-600: #005eb8; //secondary
//$green !default;
$cyan: #00a3a1; //info
//$yellow !default;
//$red !default;
//$gray-100 !default;
$gray-800: #00338d; //dark

// stylelint-disable value-keyword-case
$font-family-sans-serif: Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-monospace: 'SFMono-Regular', Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;

h1.kstyle {
  font-family: KPMGLight;
  font-size: 54px;
}

h5.kpmg {
  font-family: KPMGLight;
  font-size: 32px;
  font-weight: 400;
}

.bg-kpmg-light-purple {
  background-color: #6d2077
}

.bg-kpmg-purple {
  background-color: #470A68
}

.bg-kpmg-light-blue {
  background-color: #0091DA
}

.bg-kpmg-medium-blue {
  background-color: #005EB8
}

.bg-kpmg-dark-blue {
  background-color: #00338D
}

.bg-kpmg-light-green {
  background-color: #00A3A1
}

.cursor-pointer {
  cursor: pointer
}

.font-kpmg {
  font-family: KPMGLight;
  font-weight: 400;
}

.font-size-44 {
  font-size: 44px;
}

.font-size-32 {
  font-size: 32px;
}

@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-vue/src/index.scss';

html {
  font-size: 14px;
}

body {
  padding-top: 100px;
}

/* used in tables e.g. in regtopics */
.pointer {
  cursor: pointer;
}

/*
 * Base structure
 */

/*
 * Main content
 */

.main {
  padding: 20px;
}
@media (min-width: 768px) {
  .main {
    padding-right: 40px;
    padding-left: 40px;
  }
}
.main .page-header {
  margin-top: 0;
}

/*
 * My specific styles
 */

div.pre-wrap {
  white-space: pre-wrap;
}

.navbar-brand {
  font-size: 1.5em;
  color: red;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.navbar-top {
  height: 100px;
  font-size: 18px;
}

regbrain-highlight.regbrain-annotation {
  background-color: yellow;
}

regbrain-highlight.regbrain-annotation-transparent {
  background-color: transparent !important;
}

regbrain-highlight.regbrain-annotation-focused {
  background-color: lightblue;
}

.handbook-content {
  h1, h2, h3 {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  ol, ul {
    display: block;
    list-style-type: none;
    list-style-position: inside;
  }
  li {
    margin-bottom: 20px;
  }
  li p {
    display: inline;
  }
  .details {
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
</style>
